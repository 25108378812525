.preloader{
	height: 100vh;
	width: 100%;
	color: white;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background: #1A1A1A;
}

.preloader .texts-container{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25vh;
	width: 100%;
	font-size: 1rem;
	font-weight: 800;
	overflow: hidden;
	color: white;
}