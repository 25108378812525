.vh-50{
  height: 50vh !important;
}
.vh-75{
  height: 75vh !important;
}

.nav-bg{
  background-color: #1A1A1A !important;
}
.bg-darker{
  background-color: #1A1A1A !important;
}

.profile-bg{
  background-color: #1A1A1A !important;
}

a{
  font-size: 18px !important;
}

.profileImage{
}

.color img{
filter: grayscale(100%);
-webkit-filter: grayscale(100%);
-webkit-transition: all 1s ease;
}

.color img:hover{
filter: grayscale(100%);
filter: rgb(11, 0, 0);
-webkit-filter: grayscale(100%);
filter: none;
transition: 0.3s ease;
border: solid 30px !important;
border-color: rgb(255, 174, 0) !important;
cursor: pointer;
padding: 40px !important;
background-color: #1A1A1A !important;
}

Card{
  display: none;
}

.card-height{
  min-height: 100%;
  vertical-align: top;
}
